import {Middleware, ResponseContext, ResponseError} from "./api";

export default class NoContentMiddleware implements Middleware {
    public post?(context: ResponseContext): Promise<Response | void> {
        if (context.response.status === 204) {
            throw new ResponseError(context.response);
        }
        return Promise.resolve(context.response);
    }
}
